import React from 'react';
import './HeroSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

import Fade from 'react-reveal/Fade';
import "animate.css";
function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  subdiv,
  description,
  buttonLabel,
  img,
  alt,
  imgStart
}) {
  return (

    <>
    <div
        className={lightBg ? 'home__hero-section1' : 'home__hero-section darkBg1'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}  
          >
            
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                 <Fade bottom>
                  <div className="headln">Welcome to <span className="yel-1"> trinogenz solutions</span></div>
                  </Fade>
                 
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  
                  {description}

                </p>
                
                <a href="#section6"className="nav-links1"><Button buttonSize='btn--wide' buttonColor='blue'>
                   {buttonLabel}
                    <FiArrowRight className='Navbar-icon1' />
                  </Button></a>
               
              </div>
            </div>
            {/* <div className="second-container">
        <Fade bottom>
          <div className="headertext">
          Welcome to Trinogenz Solutions  
          </div>
        </Fade>
        
          <div className="disctext">
          Discover our
          <div className="services">
          <Typewriter options={{loop:true}}
                onInit={(typewriter) => {
                    typewriter
                        .typeString("services !")
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString("Ideas !")
                        .deleteAll()
                        .typeString("Innovations!")
                        .deleteAll()
                        .typeString("Talent !")
                        .start()
                }}
            />
          </div>
          
          </div>
          <Fade bottom>
          <div className="explore-now">
            <div className="btntext">Explore now </div>
          </div>
          </Fade>
        </div> */}
            
            <div className='col'>
              <div className='home__hero-img-wrapper'>
                
                <img src={img} alt={alt} className='home__hero-img' />
                
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
