import React from 'react'
import './contact.sass'
import { MdOutlineMailOutline } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";
import { CiLinkedin } from "react-icons/ci";
import { RiFacebookCircleLine } from "react-icons/ri";

const Contact = () => {
  return (
    <div className='Contact-Base'>
        <div className='Contact-su'>
          <div className='Contact-sub'>
            <p className='contact-subtext-top'>Let's get in touch!</p>
            <p className='contact-subtext-a'>To learn more about how our services can benefit your business, or to schedule a consultation, contact us at. </p>
            <p className='contact-subtext-b'>Contact us for quick and friendly support.</p>

            <div className='Contact-flex'>
                <div className='contact-left-container'>
                <p className='phonenumber'> <BiPhoneCall size={20} />&nbsp;&nbsp;&nbsp;+91 7708172780</p>
                <p className='gmailfix'> <MdOutlineMailOutline size={22}/> &nbsp;&nbsp;&nbsp;connect@trinogenz.com</p>
                <div className='contact-logo'>
                  <p  className='contact-us'>Connect with us</p>
                  <div className='contact-logo-flex'>
                   
                  <a className='social-icon-link1' id="mailto" href="mailto:connect@trinogenz.com" target="_newtab" ><MdOutlineMailOutline /></a>
          
            
            
              <a className='social-icon-link1' href="https://www.facebook.com/profile.php?id=61554798602507" target="_newtab"><RiFacebookCircleLine /></a>
              <a  className='social-icon-link1'href="https://www.linkedin.com/company/trinogenz-solutions/about/?viewAsMember=true" target="_newtab"><CiLinkedin /></a>
             
                  </div>
                  
                </div>
                </div>
                <div className='contact-right-container'>
                <form action="https://api.web3forms.com/submit" method="POST">
                <input type="hidden" name="access_key" value="c0a10c72-ee56-4503-a8f2-a2c11fd01b89"/>
                <p className='input-top'><input type='text'name='number' className='input-control' placeholder='Full Name' required/><br/></p>
                <p className='input-sub'><input type='text'name='mail' className='input-control' placeholder='Email' required/></p> <br/>
                <p className='textarea'><textarea name='message' placeholder='Your Message' className='input-control' required></textarea></p>
               <div className='btn-submit'> <button className='contact-submit-btn' type='submit'>Submit</button></div> 
               </form> </div>
               
                
            </div>
           </div> 
        </div>
      
    </div>
  )
}

export default Contact