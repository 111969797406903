import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import imgs from '../Footer.js/Frame 10.svg';
import { Link } from 'react-router-dom';
import { CiLinkedin } from "react-icons/ci";
import { RiFacebookCircleLine } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  
} from 'react-icons/fa';


function Footer() {
  return (
    
    <div className='footer-container'>
      <section className='footer-subscription'>
        <div className="left-container">
        <Link to='/' className='navbar-logo-b'>
          <img src={imgs} alt='' className='home__hero-imgs' />
              Trinogenz <span className="log-b">Solutions</span> 
           </Link>
        <p className='footer-subscription-heading' >
        TRINOGENZ SOLUTIONS PRIVATE LIMITED is at the forefront of innovation in software development, specializing in  enterprise solutions, mobile apps, cloud-based services. Founded in 2023, our headquarters is based in Madurai, Tamilnadu, 625706 - India.
        </p>
        <div className='social-icons'>
          
          
              <a className='social-icon-link' id="mailto" href="mailto:chinnaraja1215@gmail.com" target="_newtab" ><MdOutlineMailOutline /></a>
          
            
            
              <a className='social-icon-link' href="https://www.facebook.com/profile.php?id=61554798602507" target="_newtab"><RiFacebookCircleLine /></a>
              <a  className='social-icon-link'href="https://www.linkedin.com/company/trinogenz-solutions/about/?viewAsMember=true" target="_newtab"><CiLinkedin /></a>
             
           
          </div>
        </div>
     
          <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
          
            <h2 className='head'>About Us</h2>
             <a href="#section1"className="nav-links1">Home</a>
             <a href="#section3"className="nav-links1">About</a>
             <a href="#section3"className="nav-links1">Services</a>
             <a href="#sec-tech"className="nav-links1">Careers</a>
             <a href="#section6"className="nav-links1">Contact</a>
            
          </div>
          <div className='footer-link-items'>
            <h2 className='head'>Industries</h2>
            <Link to='/'>Finance & Fintech</Link>
            <Link to='/'>Automotive</Link>
            <Link to='/'>Enterprise</Link>
            <Link to='/'>IT / Consulting</Link>
            {/* <Link to='/'>Telecom</Link>
            <Link to='/'>Agree Tech</Link>
            <Link to='/'>Food Tech</Link>
            <Link to='/'>Health Care</Link> */}
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2 className='head'>Services</h2>
            <Link to='/'>Custom Software Development</Link>
            <Link to='/'>UI UX Design</Link>
            <Link to='/'>Cloud Services</Link>
            <Link to='/'>Mobile Application Development</Link>
            <Link to='/'>IT Consulting</Link>
            <Link to='/'>Data Analytics and Business Intelligence</Link>
            {/* <Link to='/'>SAAS Implementation</Link> */}
 
            
          </div>
        
        </div>
      </div>
         
      </section>
      
     
     
      <small className="web-register">© 2024 Trinogenz Solutions. All rights reserved.</small>
    </div>
    
  );
  
}

export default Footer;
