import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import imgs from '../components/Frame 10.svg';
import "./Navbar.css";

import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="navbar">
          <div className="navbar-container container">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={imgs} alt='' className='home__hero-imgs' />
              Trinogenz<div className="log">Solutions</div>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <a
                  href="#section1"
                  className="nav-links "
                  onClick={closeMobileMenu}
                >
                  Home
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  href="#section3"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Our Projects
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  href="#section3"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#sec-tech"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Integrations
                </a>
              </li>
              
              <li className="nav-item">
                <a
                  href="#section6"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#footer"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  About us
                </a>
              </li>
            </ul>
          </div>
          <div className="loginbtn">
            <a className="logintxt" href="#">login</a>
           
          </div>
        </nav>
        
      </IconContext.Provider>
     
    </>
  );
}

export default Navbar;
