export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  buttonLabel: 'Lets colaborate',
  imgStart: '',
  img: 'images/svg-1.svg',
  alt: 'Credit-Card'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine1: 'Our latest Projects',
  headline1: 'Crafting the future of consumer experience',
  description1:'We are proud to have partnered with BCCI and the IPL franchise for two consecutive years to deliver fantasy platform to the largest fan base of cricket fanatics in the world. We designed the best gamification experience while delivering to over a million users.',
  buttonLabel: '',
  imgStart: '',
  img1: 'images/svg-5.svg',
  alt: 'Vault',
  img2: 'images/bi-1.svg',
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Our Services',
  headline: 'What do we do?',
  description:
    'We are a full stack design and development studio crafting end-to-end digital solutions including research, ui ux design, front-end, back-end & mobile app development services',
  buttonLabel: 'Start Now',
  imgStart: 'start',
  alt: 'Vault',
  roundimg: 'images/ecl.svg',
  roundDisc: 'Design',
  bottomDisc:'Right brain, meet left brain',
  content:'Combining cognitive science, analytics and UI/UX design principles, we create custom websites that appeal to the human need for a balance between logic and emotion.  ',
  endcontent1:'Product & Digital Innovation',
  endcontent2:'UX UI Design',
  endcontent3:'Prototyping',
  leftDisc:'images/rect.svg',
  rectDisc:'Build',
  rectbottom:'Complexity without confusion',
  content1:'We take the guesswork out of website design and development with crystal clear estimates, deliverables, project management and updates. No surprises, ever.',
  leftend1:'Custom Software Development',
  leftend2:'Cloud Services',
  leftend3:'Mobile Application Development',
  leftend4:'IT Consulting',
  leftend5:'Data Analytics and Business Intelligence',
 
};

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: 'Featured Case Studies',
  headline: 'Lorem ipsum dolor sit amet, consectetor',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in laoreet turpis. Integer a nisi at purus tincidunt mollis eget nec turpis. Vivamus tempus tortor nec cursus dignissim. Suspendisse viverra vel purus ac cursus.',
  buttonLabel: 'Sign Up Now',
  img1:'images/rect1.svg',
  img2:'images/rect2.svg',
  img3:'images/rect3.svg',
  img4:'images/rect4.svg',
  rect1name:'cricket.com',
  rect2name:'Book_Room App',
  rect3name:'Paytm Money',
  rect4name:'Agriculture Application',
  crickdisc:'We are proud to have partnered with BCCI and the IPL franchise for two consecutive years to deliver fantasy platform to the largest fan base of cricket fanatics in the world. We designed the best gamification experience while delivering to over a million users.',
 button1:'UX UI Design',
 button2:'React JS',
 button3:'Node JS',
 button4:'CMS',
 buttonlabel:'View all case studies',
 
};
export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Our Mission:',
  headline: 'Our Mission',
  description:' At TRINOGENZ SOLUTIONS PRIVATE LIMITED, our mission is to empower businesses around the globe with cutting-edge software that is robust, efficient, and tailored to meet the unique demands of our clients. We are dedicated to transforming your digital landscape through innovative solutions that drive productivity and growth.',
  topline_sub:'Our Approach',
  headline_sub:'Our Approach',
  description_sub:' Our approach combines state-of-the-art technology with a deep understanding of business needs to deliver exceptional software solutions. Our team of skilled developers, designers, and consultants are committed to excellence, leveraging agile methodologies and proactive communication to ensure timely delivery and client satisfaction.',
  img1:'images/enterprise.svg',
  img2:'images/fintech.svg',
  img3:'images/it_consulting.svg',
  img4:'images/Telecom.svg',
  img5:'images/agri_tech.svg',
  img6:'images/Automotive.svg',
  img3disc:'IT / Consulting',
  img2disc:'Fintech',
  img1disc:'Enterprise',
  img4disc:'Telecom',
  img5disc:'Agri Tech',
  img6disc:'Automotive',
}
export const homeObjsix = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Our Clients !',
  headline: 'Contact us for quick and friendly support.',
  description:'Empowering your business with cutting-edge full stack solutions in various platforms',
  img1:'images/enterprise.svg',
  img2:'images/fintech.svg',
  img3:'images/it_consulting.svg',
  img4:'images/Telecom.svg',
  img5:'images/agri_tech.svg',
  img6:'images/Automotive.svg',
  img3disc:'IT / Consulting',
  img2disc:'Fintech',
  img1disc:'Enterprise',
  img4disc:'Telecom',
  img5disc:'Agri Tech',
  img6disc:'Automotive',
}