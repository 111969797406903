import React from 'react'
import './technicalstacksection.sass'
import react from '../src/Images/react.svg';
import zoom from '../src/Images/zoom.svg';
import discard from '../src/Images/discard.svg';
import Apple from '../src/Images/Applel.svg';
import Android from '../src/Images/g18.svg';
import Androi from '../src/Images/Android.svg';
import html from '../src/Images/HTML5.svg';
import g14 from '../src/Images/g14.svg';
import g2 from '../src/Images/g2.svg';
import g13 from '../src/Images/g16.svg';
import g15 from '../src/Images/g15.svg';
import g12 from '../src/Images/g12.svg';
import g11 from '../src/Images/g11.svg';
import g8 from '../src/Images/g8.svg';
import g9 from '../src/Images/g9.svg';
import g10 from '../src/Images/g10.svg';
import Github from '../src/Images/Github.svg';
import Html from '../src/Images/g17.svg';
import ember from '../src/Images/ember.svg';
import python from '../src/Images/python.svg';
import angular from '../src/Images/icons8-angularjs.svg';
import chatgpt from '../src/Images/icons8-chatgpt (1).svg';
import gitlab from '../src/Images/icons8-gitlab.svg';
import mysql from '../src/Images/icons8-mysql.svg';
import nodejs from '../src/Images/icons8-node-js.svg';
import salesforce from '../src/Images/icons8-salesforce.svg';
import Fade from 'react-reveal/Fade';

const TechnicalstackSection = () => {
  return (
    <>
   
    <div className='Tech-main-section' >
      
        <div className='Tech-sub-section'>
         <p className='Tech-top-text-section'>More than 50 Intergrations</p>
         <p className='Tech-sec-text-section'>Explore our portfolio and see the latest and greatest projects that we've brought to life.</p>
        </div>

        <div className='main-flex'>
            <Fade left>
            <div className='sub-flex'>
                <div className='logo'><img src={react} alt={"logo"}/></div>
                <div className='logo'><img src={zoom} alt={"logo"}/></div>
                <div className='logo'><img src={discard} alt={"logo"}/></div>
                <div className='logo'><img src={Apple} alt={"logo"}/></div>
                <div className='logo'><img src={Html} alt={"logo"}/></div>
                <div className='logo'><img src={Android} alt={"logo"}/></div>
            </div></Fade>
            <Fade bottom>
            <div className='sub-flex2'>
                <div className='logo'><img src={Github} alt={"logo"}/></div>
                <div className='logo'><img src={python} alt={"logo"}/></div>
                <div className='logo'><img src={gitlab} alt={"logo"}/></div>
                <div className='logo'><img src={chatgpt} alt={"logo"}/></div>
                <div className='logo'><img src={ember} alt={"logo"}/></div>    
            </div>
            </Fade>
            <Fade right>
            <div className='sub-flex3'>
                <div className='logo'><img src={mysql} alt={"logo"}/></div>
                <div className='logo logo1'><img src={nodejs} alt={"logo"}/></div>
                <div className='logo logo2'><img src={salesforce} alt={"logo"}/></div>
                <div className='logo logo3'><img src={angular} alt={"logo"}/></div>  
            </div>
            </Fade>
            <div className='tech-mop-flex'>
             <div className='mop-sub-flex1'>
                <div className='logo'><img src={html} alt={"logo"}/></div>
                <div className='logo'><img src={Androi} alt={"logo"}/></div>
                <div className='logo'><img src={g9} alt={"logo"}/></div>
                <div className='logo'><img src={g10} alt={"logo"}/></div>
             </div>

             <div className='mop-sub-flex2'>
                <div className='logo'><img src={g2} alt={"logo"}/></div>
                <div className='logo'><img src={g12} alt={"logo"}/></div>
                <div className='logo'><img src={g15} alt={"logo"}/></div>
             </div>

             <div className='mop-sub-flex3'>
                <div className='logo'><img src={g13} alt={"logo"}/></div>
                <div className='logo'><img src={g11} alt={"logo"}/></div>
                <div className='logo'><img src={g8} alt={"logo"}/></div>
             </div>
            
        </div>
            {/* <div className='view-btn'>
                 <p className='button1'><a href='/#'>View All Integrations</a></p>
            </div> */}
        </div>
        
    </div>
    
    </>
  )
}

export default TechnicalstackSection