import React from "react";
import styled from "styled-components";

import "./Herosection6.css";
import { LuPhoneCall } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import Fade from "react-reveal/Fade";
import img1 from "../Images/quantzi-logo.webp";
import img2 from "../Images/cl-img11.webp";
import img3 from "../Images/cl-img12.webp";
import img4 from "../Images/cl-img13.webp";
import img5 from "../Images/cl-img14.webp";
import img6 from "../Images/cl-img15.webp";
import img7 from "../Images/cl-img9.webp";
import img8 from "../Images/cl-img17.webp";
import img9 from "../Images/cl-img18.webp";
import img10 from "../Images/cl-img19.webp";
import img11 from "../Images/cl-img21.webp";
import img12 from "../Images/cl-img25.webp";
import img13 from "../Images/cl-img26.webp";
import img14 from '../Images/cl-img27.webp';
import img15 from '../Images/cl-img28.webp';
import img16 from '../Images/cl-img29.webp';
import img17 from '../Images/cl-img9.webp';
import img18 from '../Images/cl-img9.webp';
import img19 from '../Images/cl-img5.webp';
import img20 from '../Images/cl-img6.webp';



function HeroSection6({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  
  description,
  
}) {
  return (

    <>
    <div className="hero-container">
      <div className="title">
        <h1>Our clients !</h1>
      </div>
      <div className="sub-title">
        <span>Delivering comprehensive full stack solutions across various platforms to elevate your business.</span>
      </div>
      </div> 
    <div className="maindiv">
    <div className="wrapper">
                <div className="item item1"><img src={img1}/></div>
                <div className="item item2"><img src={img2}/></div>
                <div className="item item3"><img src={img3}/></div>
                <div className="item item4"><img src={img4}/></div>
                <div className="item item5"><img src={img5}/></div>
                <div className="item item6"><img src={img6}/></div>
                <div className="item item7"><img src={img7}/></div>
                <div className="item item8"><img src={img8}/></div>
                <div className="item item9"><img src={img9}/></div>
                <div className="item item10"><img src={img10}/></div>
                
              </div>
              <div className="wrapper2">
                <div className="itemm fbcakes"><img src={img11}/></div>
                <div className="itemm item2"><img src={img12}/></div>
                <div className="itemm item3"><img src={img13}/></div>
                <div className="itemm item4"><img src={img14}/></div>
                <div className="itemm item5"><img src={img15}/></div>
                <div className="itemm item6"><img src={img16}/></div>
                <div className="itemm item7"><img src={img17}/></div>
                <div className="itemm item8"><img src={img18}/></div>
                <div className="itemm item9"><img src={img19}/></div>
                <div className="itemm item10"><img src={img20}/></div>
                
              </div>
    </div>
             

                
              
    </>
  );
}

export default HeroSection6;
